import { useEffect, useRef } from "react";
import classes from "./chat-box.module.css";
import { socket } from "../../services/socketio";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { useFetchChats } from "../../hooks/use-fetch-chat";

export function ChatBox({ data }) {
  const [params] = useSearchParams();
  const {
    data: chats,
    setData: setMessages,
    loading,
  } = useFetchChats(getNumberFromParams(), data._id);
  const msgRef = useRef(null);

  useEffect(() => {
    socket.connect();

    // event listeners
    function onReceive(msg) {
      if (
        msg?.byAdmin === true &&
        msg.to === getNumberFromParams() &&
        msg.bid === data._id
      ) {
        setMessages((p) => [...p, msg]);
      }
    }

    function onConnect(e) {
      console.log("connected", socket.id);
    }

    socket.on("connect", onConnect);
    socket.on("receive-message", onReceive);

    return () => {
      socket.off("connect", onConnect);
      socket.off("receive-message", onReceive);
    };
  }, []);

  async function sendMessage() {
    try {
      const msg = msgRef.current.value;
      if (!msg) return;

      const number = getNumberFromParams();

      const newMessage = {
        message: msg,
        from: number,
        byAdmin: false,
        date: new Date(),
        to: data.name,
        bid: data._id,
        userSocketId: socket.id,
      };

      setMessages([...chats, newMessage]);
      socket.emit("send-message", newMessage);
      msgRef.current.value = "";
    } catch (error) {
      console.log(error);
    }
  }

  function getNumberFromParams() {
    let res;
    params.forEach((value, key) => {
      if (key === "number") res = value;
    });
    return res;
  }

  if (loading) return <p>Loading...</p>;

  return (
    <div className={classes.chat_cont}>
      <div className={classes.top_box}>
        <div className={`${classes.flex_row} ${classes.logo_cont}`}>
          <img src="/logo.svg" className={classes.logo} />
          <p className={classes.bname}>{data.name}</p>
        </div>
        <div className={`${classes.flex_row} ${classes.logo_cont}`}>
          {/* <button className={classes.backBtn}>&lt;</button> */}
          <p className={classes.title}>{getNumberFromParams()}</p>
          <p className={classes.date}>{moment().format(data.dateFormat)}</p>
        </div>
      </div>
      <div className={`${classes.chats_cont}`}>
        {chats.map((m) => (
          <div className={m.byAdmin ? classes.left : classes.right}>
            <div
              className={`${
                m.byAdmin ? classes.business_msg : classes.user_msg
              } ${classes.msg}`}
            >
              {m.message}
            </div>
          </div>
        ))}
      </div>

      <div className={classes.send_box}>
        <input
          placeholder="Type message here"
          className={classes.inp}
          ref={msgRef}
        />
        <button className={classes.send_btn} onClick={sendMessage}>
          Send
        </button>
      </div>
    </div>
  );
}
