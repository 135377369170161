import { useParams } from "react-router-dom";
import { DialPad } from "../../components/dial-pad/dial-pad";
import classes from "./login-paid.module.css";
import { useFetchBusiness } from "../../hooks/use-fetch-business";

export function LoginPaidPage() {
  const { businessId } = useParams();
  const { data, error, loading } = useFetchBusiness(businessId);

  return (
    <main className={classes.main}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <DialPad business={data} />
      )}
    </main>
  );
}
