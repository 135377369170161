import QRCode from "react-qr-code";
import { getPwaUrl } from "../../constants/base-url";

import classes from "./qr.module.css";
import { useNavigate } from "react-router-dom";

export function QR({ business, onClose }) {
  const pwaLoginLink = `${getPwaUrl()}/${business?._id}`;
  const navigate = useNavigate();

  return (
    <div>
      <div className={classes.x_header}>
        <button onClick={onClose}>
          <img style={{ height: "1rem", width: "1rem" }} src="/x.svg" />
        </button>
      </div>

      <div style={{ position: "relative", border: "20px solid #FF8120" }}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={pwaLoginLink}
          viewBox={`0 0 256 256`}
        />
        <img
          src="/qr-logo.png"
          style={{
            position: "absolute",
            width: 50,
            height: 50,
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        />
      </div>
      <h4 style={{ fontSize: 12, fontWeight: "bold" }}>{business.name}</h4>

      {/* Bottom Two Buttons */}
      <div className={classes.log}>
        <button onClick={() => navigate("/")}>Keypad</button>
        <button onClick={() => navigate("/waiting-list")}>Waiting List</button>
      </div>
    </div>
  );
}
