// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auto_main__VEeZT {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auto_signin_cont__4VJ9K {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.auto_signin_btn__wkFCz {
  padding: 0.35rem 0.85rem;
  background-color: #ff8120;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}

.auto_signin_btn__wkFCz:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.auto_preview_sign__oHqZF {
  border: 1px solid #ff8120;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.auto_preview_sign__oHqZF .auto_sign__YFync {
  width: 7rem;
}

.auto_checkbox_cont__TVvjf {
  max-width: 400px;
}

@media only screen and (max-width: 700px) {
  .auto_main__VEeZT {
    padding: 1rem;
  }

  .auto_checkbox_cont__TVvjf {
    /* max-width: 100vw; */
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/auto/auto.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".main {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.signin_cont {\n  margin-top: 1.5rem;\n  display: flex;\n  justify-content: center;\n}\n\n.signin_btn {\n  padding: 0.35rem 0.85rem;\n  background-color: #ff8120;\n  border: none;\n  border-radius: 3px;\n  color: #fff;\n  cursor: pointer;\n}\n\n.signin_btn:disabled {\n  background-color: #e0e0e0;\n  cursor: not-allowed;\n}\n\n.preview_sign {\n  border: 1px solid #ff8120;\n  border-radius: 0.5rem;\n  display: flex;\n  justify-content: center;\n  padding: 0.5rem;\n}\n\n.preview_sign .sign {\n  width: 7rem;\n}\n\n.checkbox_cont {\n  max-width: 400px;\n}\n\n@media only screen and (max-width: 700px) {\n  .main {\n    padding: 1rem;\n  }\n\n  .checkbox_cont {\n    /* max-width: 100vw; */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `auto_main__VEeZT`,
	"signin_cont": `auto_signin_cont__4VJ9K`,
	"signin_btn": `auto_signin_btn__wkFCz`,
	"preview_sign": `auto_preview_sign__oHqZF`,
	"sign": `auto_sign__YFync`,
	"checkbox_cont": `auto_checkbox_cont__TVvjf`
};
export default ___CSS_LOADER_EXPORT___;
