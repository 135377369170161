import { useNavigate, useParams } from "react-router-dom";
import { NamePad } from "../../components/name-pad/name-pad";
import { useFetchBusiness } from "../../hooks/use-fetch-business";
import classes from "./name-form.module.css";

export function NameForm() {
  const { data, error, loading } = useFetchBusiness();
  const { phone } = useParams();
  const navigate = useNavigate();

  if (error) return <p>{error}</p>;

  return (
    <main className={classes.main}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <NamePad business={data} phone={phone} onCancel={() => navigate(-1)} />
      )}
    </main>
  );
}
