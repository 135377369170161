// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-input-modal_cont__QZBWd {
  border: 1px solid #d3e2e4;
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 2;
}

.time-input-modal_flex_row__QviBT {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.time-input-modal_inp__X-j7L {
  font-size: 1.1rem;
  padding: 0.2rem;
  max-width: 2.5rem;
  border: 1px solid #d3e2e4;
  background-color: #f0f4fd;
  border-radius: 2px;
  font-weight: 600;
}

.time-input-modal_semi__K7b\\+W {
  font-weight: 600;
}

.time-input-modal_ampm_btn__Zqnl- {
  cursor: pointer;
  padding: 0.1rem;
  border: 1px solid #d3e2e4;
  /* background-color: #f0f4fd; */
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
  font-weight: 600;
}

.time-input-modal_bottom_cont__NRlVG {
  border-top: 1px solid #f0f4fd;
  padding-top: 0.25rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
}

.time-input-modal_btn__0WBxJ {
  background-color: transparent;
  border: none;
  font-weight: 700;
}

.time-input-modal_btn__0WBxJ:nth-child(1) {
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/time-input-modal.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB,+BAA+B;EAC/B,iBAAiB;EACjB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".cont {\n  border: 1px solid #d3e2e4;\n  border-radius: 5px;\n  background-color: #fff;\n  padding: 1rem;\n  position: absolute;\n  right: 1rem;\n  top: 1rem;\n  z-index: 2;\n}\n\n.flex_row {\n  margin-top: 0.5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.inp {\n  font-size: 1.1rem;\n  padding: 0.2rem;\n  max-width: 2.5rem;\n  border: 1px solid #d3e2e4;\n  background-color: #f0f4fd;\n  border-radius: 2px;\n  font-weight: 600;\n}\n\n.semi {\n  font-weight: 600;\n}\n\n.ampm_btn {\n  cursor: pointer;\n  padding: 0.1rem;\n  border: 1px solid #d3e2e4;\n  /* background-color: #f0f4fd; */\n  font-size: 0.7rem;\n  margin-bottom: 0.2rem;\n  font-weight: 600;\n}\n\n.bottom_cont {\n  border-top: 1px solid #f0f4fd;\n  padding-top: 0.25rem;\n  display: flex;\n  justify-content: space-between;\n  margin-top: 0.25rem;\n}\n\n.btn {\n  background-color: transparent;\n  border: none;\n  font-weight: 700;\n}\n\n.btn:nth-child(1) {\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cont": `time-input-modal_cont__QZBWd`,
	"flex_row": `time-input-modal_flex_row__QviBT`,
	"inp": `time-input-modal_inp__X-j7L`,
	"semi": `time-input-modal_semi__K7b+W`,
	"ampm_btn": `time-input-modal_ampm_btn__Zqnl-`,
	"bottom_cont": `time-input-modal_bottom_cont__NRlVG`,
	"btn": `time-input-modal_btn__0WBxJ`
};
export default ___CSS_LOADER_EXPORT___;
