export const FIELDS = {
  AUTO: {
    name: "AUTO",
    img: "/fields/automatic.svg",
    info: "Automatic",
  },
  AUTO_NAME: {
    name: "AUTO_NAME",
    img: "/fields/automatic_name.svg",
    info: "Automatic Name",
  },
  TEXT: {
    name: "TEXT",
    img: "/fields/textfield.svg",
    info: "Textfield",
  },
  SIGN: {
    name: "SIGN",
    img: "/fields/sign.svg",
    info: "Signature",
  },
  TICK: {
    name: "TICK",
    img: "/fields/tick.svg",
    info: "Tick Options",
  },
  SELECT: {
    name: "SELECT",
    img: "/fields/select.svg",
    info: "Select Options",
  },
  TIME_IN: {
    name: "TIME_IN",
    img: "/fields/in-time.svg",
    info: "Time in",
  },
  TIME_OUT: {
    name: "TIME_OUT",
    img: "/fields/out-time.svg",
    info: "Time Out",
  },
  CAL: {
    name: "CAL",
    img: "/fields/cal.svg",
    info: "Date",
  },
  AUTO_CAL: {
    name: "AUTO_CAL",
    img: "/fields/auto-date.svg",
    info: "Auto Date",
  },
  TIME_30: {
    name: "TIME_30",
    img: "/fields/time30.svg",
    info: "Time 30",
  },
  STOPWATCH: {
    name: "STOPWATCH",
    img: "/fields/stopwatch.svg",
    info: "Stopwatch",
  },
  HIDE: {
    name: "HIDE",
    img: "/fields/crosseye.svg",
    info: "Hide",
  },
  AUTO_IN: {
    name: "AUTO_IN",
    img: "/fields/auto-in.svg",
    info: "Automatic Intime",
  },
  AUTO_OUT: {
    name: "AUTO_OUT",
    img: "/fields/auto-out.svg",
    info: "Automatic Outtime",
  },
};

export const TYPES = {
  ADD: {
    name: "ADD",
    img: "/fields/add.svg",
    info: "Add Field",
  },
  YES: {
    name: "YES",
    img: "/fields/yes.svg",
    info: "Yes",
  },
  NO: {
    name: "NO",
    img: "/fields/no.svg",
    info: "No",
  },
  ADD_FILE: {
    name: "ADD_FILE",
    img: "/fields/file-add.svg",
    info: "Add File",
  },
  AUTO_DATE: {
    name: "AUTO DATE",
    img: "/fields/calendar.svg",
    info: "Automatic Date",
  },
  HIDE: {
    name: "HIDE",
    img: "/fields/crosseye.svg",
    info: "Hide",
  },
};
