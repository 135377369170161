import axios from "axios";
import { useEffect, useState } from "react";
import { HTTP_STATUS } from "../constants/http-status";
import { getMsgFromAxiosErr } from "../utils/get-axios-err-msg";
import { getBaseUrl } from "../constants/base-url";

export function useFetchPrevVisit(num, business) {
  const [data, setData] = useState();
  const [error, setErr] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (num) callApiAsync(num);
  }, []);

  async function callApiAsync(number) {
    try {
      const res = await axios.get(`${getBaseUrl()}/pwa/visit/${number}`, {
        params: {
          businessId: business._id,
        },
      });
      setLoading(false);

      if (res.status !== HTTP_STATUS.OK) {
        setErr(res?.data?.msg || "Something wen't wrong");
      } else {
        setData(res.data?.visit);
      }
    } catch (error) {
      setLoading(false);
      const msg = getMsgFromAxiosErr(error);
      setErr(msg);
    }
  }

  return { data, error, loading };
}
