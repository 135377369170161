export function getBaseUrl() {
  const URL =
    process.env.REACT_APP_ENV === "DEV"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  // console.log("Returning", URL);
  return URL;
}

export function getPwaUrl() {
  const URL =
    process.env.REACT_APP_ENV === "DEV"
      ? process.env.REACT_APP_PWA_LINK_DEV
      : process.env.REACT_APP_PWA_LINK_PROD;

  // console.log("Returning", URL);
  return URL;
}
