import classes from "./chat.module.css";
import { useFetchBusiness } from "../../hooks/use-fetch-business";
import { ChatBox } from "../../components/chat/chat-box";
import { useEffect } from "react";
import { socket } from "../../services/socketio";

export function ChatPage() {
  const { data, error, loading } = useFetchBusiness();

  if (error) return <p>{error}</p>;

  useEffect(() => {
    // socketio

    function onConnect(e) {
      console.log("connected", socket);
    }

    socket.on("connect", onConnect);

    return () => {
      socket.off("connect", onConnect);
    };
  }, []);

  return (
    <main className={classes.main}>
      {loading ? <p>Loading...</p> : <ChatBox data={data} />}
    </main>
  );
}
