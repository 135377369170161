import { useEffect, useRef, useState } from "react";
import { useFetchBusiness } from "../../hooks/use-fetch-business";
import classes from "./message.module.css";
import { getMsgFromAxiosErr } from "../../utils/get-axios-err-msg";
import { errorToast } from "../../utils/notifications";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { getBaseUrl } from "../../constants/base-url";
import { HTTP_STATUS } from "../../constants/http-status";

export function MessagePage() {
  const { data, error, loading } = useFetchBusiness();
  const [params] = useSearchParams();

  // states
  const [messages, setMessages] = useState([]);
  const [phone, setPhone] = useState(null);
  const [name, setName] = useState(null);

  // refs
  const msgRef = useRef();

  useEffect(() => {
    extractSearchParams();
  }, []);

  function extractSearchParams() {
    params.forEach((value, key) => {
      if (key === "phone") setPhone(value);
      if (key === "name") setName(value);
    });
  }

  async function handleSendClick() {
    try {
      const message = msgRef.current.value;
      if (!message) return;

      const form = {
        message,
        businessId: data._id,
        name,
        phone,
      };

      if (!form.businessId || !form.name || !form.phone) {
        return errorToast("Name, Phone, Business is required");
      }

      const oldMessages = [...messages];
      setMessages([...oldMessages, form]);

      const res = await axios.post(`${getBaseUrl()}/pwa/send-message`, form);
      if (res.status !== HTTP_STATUS.OK) {
        setMessages(oldMessages);
        return errorToast("Something went wrong");
      } else {
        msgRef.current.value = "";
      }
    } catch (error) {
      const msg = getMsgFromAxiosErr(error);
      errorToast(msg);
    }
  }

  if (error) return <p>{error}</p>;

  return (
    <div className={classes.main}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className={classes.container}>
          <h4>{data?.name}</h4>

          {phone && name ? (
            <div>
              <p>Sending from: {phone}</p>
              <p> {name}</p>
            </div>
          ) : (
            <div>
              <input placeholder="Enter Phone number" />
              <input placeholder="Enter name" />
            </div>
          )}

          <br />
          {/* Map messages */}
          {messages.map((m) => (
            <div>{m.message}</div>
          ))}
          <br />

          <div>
            <input placeholder="Type message" ref={msgRef} />
            <button onClick={handleSendClick}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
}
