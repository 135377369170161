// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.send-file-copy_main__N-T9F {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.send-file-copy_header__vqjgb {
  display: flex;
  justify-content: flex-end;

  .send-file-copy_cross__QvfQh {
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
  }
}

.send-file-copy_top_txt__60xcs {
  text-align: center;
  font-weight: 500;

  p {
    color: #ff8120;
    margin-bottom: 1rem;
  }
}

.send-file-copy_container__3ClTl {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 25rem;

  .send-file-copy_inp_title__UmtcC {
    width: 100%;
    font-size: 0.85rem;
    font-weight: 500;
  }

  input {
    padding: 0.5rem 0.8rem;
    margin-bottom: 2rem;
    width: 100%;
    border: 1px solid #797979;
    border-radius: 4px;
  }

  .send-file-copy_fwbtn__amA\\+3 {
    background-color: #ff8120;
    border: none;
    border-radius: 2px;
    color: #fff;
    padding: 0.5rem 0.8rem;
    font-weight: 500;
  }

  .send-file-copy_tq_btn__M3-lq {
    border: 1px solid #000;
    color: #ff8120;
    background-color: transparent;
  }
}

@media only screen and (max-width: 530px) {
  .send-file-copy_container__3ClTl {
    min-width: 90vw;
    padding: 1.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/send-file-copy/send-file-copy.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;;EAEb,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;;EAEzB;IACE,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;AACF;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;;EAEhB;IACE,cAAc;IACd,mBAAmB;EACrB;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;;EAEhB;IACE,WAAW;IACX,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,cAAc;IACd,6BAA6B;EAC/B;AACF;;AAEA;EACE;IACE,eAAe;IACf,eAAe;EACjB;AACF","sourcesContent":[".main {\n  width: 100vw;\n  height: 100vh;\n\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n}\n\n.header {\n  display: flex;\n  justify-content: flex-end;\n\n  .cross {\n    background-color: transparent;\n    border: none;\n    font-size: 1.25rem;\n    cursor: pointer;\n  }\n}\n\n.top_txt {\n  text-align: center;\n  font-weight: 500;\n\n  p {\n    color: #ff8120;\n    margin-bottom: 1rem;\n  }\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  min-width: 25rem;\n\n  .inp_title {\n    width: 100%;\n    font-size: 0.85rem;\n    font-weight: 500;\n  }\n\n  input {\n    padding: 0.5rem 0.8rem;\n    margin-bottom: 2rem;\n    width: 100%;\n    border: 1px solid #797979;\n    border-radius: 4px;\n  }\n\n  .fwbtn {\n    background-color: #ff8120;\n    border: none;\n    border-radius: 2px;\n    color: #fff;\n    padding: 0.5rem 0.8rem;\n    font-weight: 500;\n  }\n\n  .tq_btn {\n    border: 1px solid #000;\n    color: #ff8120;\n    background-color: transparent;\n  }\n}\n\n@media only screen and (max-width: 530px) {\n  .container {\n    min-width: 90vw;\n    padding: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `send-file-copy_main__N-T9F`,
	"header": `send-file-copy_header__vqjgb`,
	"cross": `send-file-copy_cross__QvfQh`,
	"top_txt": `send-file-copy_top_txt__60xcs`,
	"container": `send-file-copy_container__3ClTl`,
	"inp_title": `send-file-copy_inp_title__UmtcC`,
	"fwbtn": `send-file-copy_fwbtn__amA+3`,
	"tq_btn": `send-file-copy_tq_btn__M3-lq`
};
export default ___CSS_LOADER_EXPORT___;
