import { useState } from "react";
import classes from "./time-input-modal.module.css";
import moment from "moment";

export function TimeInputModal({ timeFormat, onSave, defaultValue }) {
  const initState = {
    hh: defaultValue
      ? moment(defaultValue, timeFormat).format("hh")
      : moment().format("hh"),
    mm: defaultValue
      ? moment(defaultValue, timeFormat).format("mm")
      : moment().format("mm"),
  };

  const [time, setTime] = useState(initState);
  const [ampm, setAmpm] = useState(
    defaultValue ? moment(defaultValue, timeFormat).format("A") : "AM"
  );

  function handleSave(e) {
    e.stopPropagation();
    const res = `${time.hh}:${time.mm} ${ampm}`;
    const formattedTime = moment(res, "hh:mm A").format(timeFormat);
    onSave(formattedTime);
  }

  return (
    <div className={classes.cont}>
      <p>Select Time</p>

      <div className={classes.flex_row}>
        <input
          onChange={(e) => setTime((p) => ({ ...p, hh: e.target.value }))}
          value={time.hh}
          className={classes.inp}
        />
        <span className={classes.semi}>:</span>
        <input
          onChange={(e) => setTime((p) => ({ ...p, mm: e.target.value }))}
          value={time.mm}
          className={classes.inp}
        />
        <div>
          <div
            className={classes.ampm_btn}
            onClick={() => setAmpm("AM")}
            style={{ backgroundColor: ampm === "AM" ? "#D4DBEA" : undefined }}
          >
            AM
          </div>
          <div
            onClick={() => setAmpm("PM")}
            className={classes.ampm_btn}
            style={{ backgroundColor: ampm === "PM" ? "#D4DBEA" : undefined }}
          >
            PM
          </div>
        </div>
      </div>

      <div className={classes.bottom_cont}>
        <button className={classes.btn} onClick={() => setTime(initState)}>
          Reset
        </button>
        <button className={classes.btn} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}
