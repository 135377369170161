import { useParams } from "react-router-dom";
import classes from "./home.module.css";
import { DialPad } from "../../components/dial-pad/dial-pad";
import { useFetchBusiness } from "../../hooks/use-fetch-business";

export function HomePage() {
  const { businessId } = useParams();
  const { data, error, loading } = useFetchBusiness(businessId);

  if (error) return <p>{error}</p>;

  return (
    <main className={classes.main}>
      {loading ? <p>Loading...</p> : <DialPad business={data} />}
    </main>
  );
}
