const TIME_FORMATS = {
  "H:MM XM": "h:mm A", // Default for all accounts
  "HH:MM:SS XM": "hh:mm:ss A",
  "HH:MM": "h:mm",
  "HH:MM XM": "hh:mm A",
};

const DATE_FORMATS = {
  "DD/MM/YYYY": "DD/MM/YYYY", // Default for all accounts
  "YYYY/MM/DD": "YYYY/MM/DD",
  "MM-DD-YYYY": "MM-DD-YYYY",
  "YYYY-MM-DD": "YYYY-MM-DD",
  "MM.DD.YYYY": "MM.DD.YYYY",
  "DD.MM.YYYY": "DD.MM.YYYY",
  "MMMM Do YYYY": "MMMM Do YYYY",
};

export const DEFAULT_TIME_FORMAT = TIME_FORMATS["H:MM XM"];
export const DEFAULT_DATE_FORMAT = DATE_FORMATS["DD/MM/YYYY"];
