import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFetchBusiness } from "../../hooks/use-fetch-business";
import classes from "./auto.module.css";
import { useEffect, useState } from "react";
import { Field } from "../../components/auto/field";
import { FIELDS } from "../../constants/fields";
import { Checkbox } from "../../components/ui/checkbox";
import { SignForm } from "../../components/modal/sign-form";
import { useAtom } from "jotai";
import { DisableBtnAtom, FieldDataAtom } from "../../atoms/auto-screen-atoms";
import { errorToast } from "../../utils/notifications";
import { getBaseUrl } from "../../constants/base-url";
import { getMsgFromAxiosErr } from "../../utils/get-axios-err-msg";
import axios from "axios";
import { HTTP_STATUS } from "../../constants/http-status";
import { removeFileExtension } from "../../utils/conversation";

export function AutoForm() {
  const { data, error, loading: bloading } = useFetchBusiness();

  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [signForm, setSignForm] = useState(false);
  const [signedImg, setSignedImg] = useState(null);

  const [disabled, setDisabled] = useAtom(DisableBtnAtom);
  const [fieldsData] = useAtom(FieldDataAtom);

  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    extractSearchParams();
    setDisabled(1);
  }, []);

  function extractSearchParams() {
    let fullName = "";

    params.forEach((value, key) => {
      if (key === "name") fullName += value;
      if (key === "mname" && value) fullName += " " + value;
      if (key === "lname" && value) fullName += " " + value;
    });

    setName(fullName);
  }

  function handleOnSignSave(blob) {
    setSignedImg(blob);
    setSignForm(false);
    setChecked(true);
  }

  async function signinUser() {
    try {
      for (const id of Object.keys(fieldsData)) {
        if (fieldsData[id] === false) {
          console.log(fieldsData);
          return errorToast("Please click on every field and fill them");
        }
      }

      let newForm = {
        businessId: null,
        number: null,
        name: null,
        lastname: null,
        midname: null,
      };

      params.forEach((value, key) => {
        if (key === "name" && value) newForm.name = value;
        if (key === "mname" && value) newForm.midname = value;
        if (key === "lname" && value) newForm.lastname = value;
        if (key === "number" && value) newForm.number = value;
        if (key === "bid" && value) newForm.businessId = value;
      });

      if (!newForm.number) throw new Error("There should be a number");
      if (!newForm.name || !newForm.lastname) {
        return errorToast("Firstname and Lastname is required");
      }

      const formData = new FormData();
      Object.keys(newForm).forEach((key) => formData.append(key, newForm[key]));
      console.log(fieldsData);
      formData.append("others", JSON.stringify(fieldsData));

      if (signedImg) formData.append("sign", signedImg);

      setLoading(true);
      const res = await axios.post(`${getBaseUrl()}/pwa/login`, formData);
      setLoading(false);

      if (res.status !== HTTP_STATUS.CREATED) {
        return errorToast("Something went wrong");
      }

      const signedUrl = res.data?.sign?.url;
      const preview = res?.data?.previewPdf;

      if (preview === true && signedUrl) {
        // navigate to send attach file
        let linkToSendFile = `/send-file`;
        if (res.data?.sign?.url) {
          linkToSendFile += `?sign=${signedUrl}`;
        }

        let fname = newForm.name;
        let mname = newForm.midname;
        let lname = newForm.lastname;

        if (fname) linkToSendFile += `&fname=${fname}`;
        if (mname) linkToSendFile += `&mname=${mname}`;
        if (lname) linkToSendFile += `&lname=${lname}`;

        navigate(linkToSendFile);
      } else {
        // navigate to waitingList with the full name
        let name = newForm.name + " ";
        if (newForm.midname) name += `${newForm.midname} `;
        if (newForm.lastname) name += `${newForm.lastname} `;
        const linkToWaitingList = `/waiting-list?name=${name}`;
        localStorage.setItem("number", newForm.number);
        navigate(linkToWaitingList);
      }
    } catch (error) {
      console.log("handleSaveClick() :", error);
      setLoading(false);
      const msg = getMsgFromAxiosErr(error);
      errorToast(msg);
    }
  }

  if (error) return <p>{error}</p>;

  return (
    <main className={classes.main}>
      {bloading || loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {data?.fields?.map((f) => (
            <Field
              selectOptions={JSON.parse(data.selectOptions || "[]")}
              timeFormat={data?.timeFormat}
              dateFormat={data?.dateFormat}
              field={f}
              autofill={f.feature === FIELDS.AUTO_NAME.name ? name : false}
            />
          ))}

          {data?.file?.name && (
            <div className={classes.checkbox_cont}>
              <Checkbox
                onClick={() => setSignForm(!signForm)}
                checked={checked}
                label={`I agree to apply my name, initials, and signature where applicable on ${removeFileExtension(
                  data?.file?.name
                )}`}
              />
            </div>
          )}

          {signedImg && (
            <div className={classes.preview_sign}>
              <img
                src={URL.createObjectURL(signedImg)}
                className={classes.sign}
              />
            </div>
          )}

          <div className={classes.signin_cont}>
            <button
              className={classes.signin_btn}
              // disabled={disabled < data.fields?.length}
              onClick={signinUser}
            >
              Sign in
            </button>
          </div>
        </div>
      )}

      {signForm && (
        <SignForm
          onClose={() => setSignForm(false)}
          onSave={handleOnSignSave}
        />
      )}
    </main>
  );
}
