import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classes from "./waiting-list.module.css";
import moment from "moment";
import { useFetchWaitingList } from "../hooks/use-fetch-waitinglist";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../constants/date-time-formats";

export function WaitingListPage() {
  const navigate = useNavigate();

  return (
    <main className={classes.main}>
      <div className={classes.container}>
        <WaitingList onTimerEnd={() => navigate("/")} />
      </div>
    </main>
  );
}

export function WaitingList({ onTimerEnd }) {
  const [params] = useSearchParams();
  const [name, setName] = useState(null);
  const [hideTimer, setHideTimer] = useState(true);
  const [timer, setTimer] = useState(null);
  const { data, error, loading } = useFetchWaitingList();
  const navigate = useNavigate();

  useEffect(() => {
    extractSearchParams();

    // Set up a timer to navigate to the home screen after 5 seconds
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the timer when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (timer === 0) onTimerEnd();
  }, [timer]);

  function extractSearchParams() {
    params.forEach((value, key) => {
      if (key === "name") {
        setTimer(5);
        setHideTimer(false);
        setName(value || "Patient Name");
      }
    });
  }

  if (loading) return <p>Loading...</p>;
  if (!data) return <p>No Data Found</p>;
  if (!data.business) return <p>No Business Found</p>;
  if (!data.waitingList) return <p>No Waiting List Found</p>;

  return (
    <>
      {/* {!hideTimer && timer && <p className={classes.timer}>{timer}s</p>} */}
      <div className={classes.date_header}>
        <p>
          {moment().format(data?.business?.dateFormat || DEFAULT_DATE_FORMAT)}
        </p>
        <p>
          {moment().format(data?.business?.timeFormat || DEFAULT_TIME_FORMAT)}
        </p>
      </div>

      <div className={classes.header}>
        {name && (
          <img className={classes.img} src="/waiting-list/verified.png" />
        )}
        <h4>{name}</h4>
      </div>

      <h4 className={classes.businessName}>{data?.business?.name}</h4>

      <div className={classes.waiting_table_cont}>
        <table className={classes.waiting_table}>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Time In</th>
          </tr>
          {data?.waitingList?.map((acc, idx) => (
            <tr key={idx}>
              <td>{acc.no}</td>
              <td>{acc.initial}</td>
              <td>{acc.phone}</td>
              <td>
                <span className={classes.timeSpan}>
                  {moment(acc.createdAt).format(
                    data?.business?.timeFormat || DEFAULT_TIME_FORMAT
                  )}
                </span>
              </td>
            </tr>
          ))}
        </table>
      </div>

      {/* Bottom Two Buttons */}
      <div className={classes.log}>
        <button onClick={() => navigate("/share")}>Share</button>
        <button onClick={() => navigate("/")}>Keypad</button>
      </div>
    </>
  );
}
