import classes from "./share.module.css";
import { useFetchBusiness } from "../../hooks/use-fetch-business";
import { SharePanel } from "../../components/share/share-panel";
import { SharePanelNew } from "../../components/share/share-panel-new";

export function SharePage() {
  const { data, error, loading } = useFetchBusiness();

  if (error) return <p>{error}</p>;

  return (
    <main className={classes.main}>
      {loading ? <p>Loading...</p> : <SharePanelNew business={data} />}
    </main>
  );
}
