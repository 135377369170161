import axios from "axios";
import { useEffect, useState } from "react";
import { HTTP_STATUS } from "../constants/http-status";
import { getMsgFromAxiosErr } from "../utils/get-axios-err-msg";
import { getBaseUrl } from "../constants/base-url";

export function useFetchChats(from, businessId) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (from && businessId) callApiAsync();
  }, [from, businessId]);

  async function callApiAsync() {
    try {
      setLoading(true);
      const res = await axios.get(`${getBaseUrl()}/message/chat`, {
        params: {
          businessId,
          userNumber: from,
        },
      });

      if (res.status !== HTTP_STATUS.OK) {
        return setError("Something wen't wrong");
      }

      setData(res?.data?.chats || []);
      setLoading(false);
    } catch (error) {
      const msg = getMsgFromAxiosErr(error);
      setError(msg);
      setLoading(false);
    }
  }

  return { data, error, loading, setData };
}
